import React from "react";
import { Link, useLocation } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
  to: string;
  className?: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CustomLink(props: IProps) {
  const { children, to, className } = props;
  const params = [];
  let query = useQuery();
  if (query.get("embed")) params.push("embed=true");
  return (
    <Link
      className={className}
      to={`${to}${params.length ? `?${params.join("&")}` : ""}`}
    >
      {children}
    </Link>
  );
}
