import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ITEMS } from "../queries";
import { Spinner, Link } from "../components";

function Products() {
  const { data } = useQuery(GET_ITEMS);
  return (
    <div>
      <section className="text-center ">
        <div className="row">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">Bricothèque</h1>
            <p className="lead text-muted">
              A la bricothèque du vignoble nantais, nous avons fait le choix de
              privilégier des outils de qualité, si possible réparables, pour
              des raisons de sécurité et de durabilité.
            </p>
          </div>
        </div>
      </section>
      <div className="container">
        {data ? <ItemList items={data.items} /> : <Spinner />}
      </div>
    </div>
  );
}

interface IItemList {
  items?: any[];
}

function ItemList(props: IItemList) {
  if (!props.items) return null;
  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {props.items.map((item) => (
        <Item key={item._id} item={item} />
      ))}
    </div>
  );
}

interface IItem {
  item?: any;
}

function Item(props: IItem) {
  if (!props.item) return null;
  const { _id, name, picture, price } = props.item;
  const link = "/" + _id;
  return (
    <div className="col">
      <Link to={link}>
        <div className="card h-100">
          <img className="img-product-list" src={picture} />
          <div className="card-body">
            <h5 className="card-title text-center">{name}</h5>
            <h6 className="text-right">{price}</h6>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Products;
