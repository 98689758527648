import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Product, Products } from "./views";
import { Nav, EndPhrase } from "./components";

function App() {
  return (
    <div>
      <Router>
        <Nav />
        <main className="container">
          <Switch>
            <Route exact path="/">
              <Products />
            </Route>
            <Route path="/:id" children={<Product />} />
          </Switch>
          <EndPhrase />
        </main>
      </Router>
    </div>
  );
}

export default App;
