import React from "react";

interface IProps {
  src?: string;
}

export default function (props: IProps) {
  if (!props.src) return null;
  function youtube_parser(url: string) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }
  return (
    <iframe
      id="ytplayer"
      width="100%"
      height="300"
      src={`https://youtube.com/embed/${youtube_parser(props.src)}`}
    />
  );
}
