import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ITEM } from "../queries";
import ReactMarkdown from "react-markdown";
import { Spinner, Calendar, Youtube, Link } from "../components";
import { DateSelectArg } from "@fullcalendar/react";

function Product() {
  const [selectedDates, setSelectedDates] = useState<DateSelectArg>();
  let { id } = useParams<{ id: string }>();
  const { data } = useQuery(GET_ITEM, {
    variables: { _id: id },
  });

  if (!data) return <Spinner />;

  const { name, description, picture, brand, reference, price, youtubeLink } =
    data.item;
  const bookings = data.bookings.map((booking: any) => ({
    ...booking,
    display: "background",
    allDay: true,
    overlap: false,
  }));
  data.lockedDates &&
    data.lockedDates.forEach((date: any) => {
      bookings.push({
        ...date,
        display: "background",
        allDay: true,
        classNames: ["fc-locked-event"],
        overlap: true, // use overlap to lock event
      });
    });

  const setEndDateInclusive = (args: DateSelectArg) => {
    args.end.setSeconds(args.end.getSeconds() - 1);
    args.endStr = args.end.toISOString().split("T")[0];
    return args;
  };
  const handleDateSelect = (args: DateSelectArg) => {
    setSelectedDates(setEndDateInclusive(args));
  };
  const clickOnValidate = () => {
    if (!selectedDates) return;
    window.open(
      `https://airtable.com/shrSx2XzMIqRkigMs?prefill_dateDebut=${selectedDates.startStr}&prefill_dateFin=${selectedDates.endStr}&prefill_objet=${id}`
    );
  };
  const toLocaleDateString = (date: Date) => {
    return date.toLocaleDateString("fr");
  };

  const DateConfirmation = () => {
    if (!selectedDates) return null;
    return (
      <div className="row date-confirmation-row">
        <div className="col-lg-10">
          <div className="alert alert-primary" role="alert">
            Confirmez votre réservation du{" "}
            {toLocaleDateString(selectedDates?.start)} au{" "}
            {toLocaleDateString(selectedDates?.end)}
          </div>
        </div>
        <div className="col-lg-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={clickOnValidate}
          >
            Valider
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Retour</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {name}
          </li>
        </ol>
      </nav>
      <div className="product-block">
        <div className="row">
          <div className="col-lg-6">
            <img className="img-product text-center" src={picture} />
          </div>
          <div className="col-lg-6">
            <h1>{name}</h1>
            <h2 className="price">{price}</h2>
            <h5>Marque : {brand}</h5>
            <h5>Référence : {reference}</h5>
            <Youtube src={youtubeLink} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-8 mx-auto">
          <hr className="dropdown-divider" />
          <h3>Sélectionnez un créneau dans le calendrier puis valider</h3>
          <i>Rester appuyé pour sélectionner la plage de votre réservation dans le calendrier</i>
          <Calendar events={bookings} handleDateSelect={handleDateSelect} />
          <DateConfirmation />
          <h3>Description</h3>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default Product;
