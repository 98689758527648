import React from "react";
import FullCalendar, { DateSelectArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

interface IProps {
  events?: any[];
  handleDateSelect?: (args: DateSelectArg) => void;
}

export default function (props: IProps) {
  const calendarRef = React.createRef<FullCalendar>();

  const daysOfWeek = [1,3,6];
  const selectAllow = (selectInfo: { start: Date; end: Date }) => {
    selectInfo.end.setSeconds(selectInfo.end.getSeconds() - 1); // use include dates
    if (!daysOfWeek.includes(selectInfo.start.getDay())) return false;
    if (!daysOfWeek.includes(selectInfo.end.getDay())) return false;
    if (selectInfoIsOnLockedDates(selectInfo)) return false;
    return true;
  };
  const selectOverlap = (event: any) => {
    return event.overlap;
  };
  const selectInfoIsOnLockedDates = (selectInfo: {
    start: Date;
    end: Date;
  }) => {
    return calendarRef.current
      ?.getApi()
      .getEvents()
      .filter((event) => event.overlap)
      .some((event) => {
        if (!event.start || !event.end) return false;
        if (event.start <= selectInfo.start && selectInfo.start <= event.end)
          return true;
        if (event.start <= selectInfo.end && selectInfo.end <= event.end)
          return true;
      });
  };
  return (
    <div className="calendar">
      <FullCalendar
        locale={frLocale}
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: undefined,
          center: "title",
        }}
        timeZone="local"
        select={props.handleDateSelect}
        initialEvents={props.events}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        contentHeight={500}
        selectAllow={selectAllow}
        selectConstraint={{ start: new Date() }}
        selectOverlap={selectOverlap}
        eventOverlap={false}
        businessHours={{ daysOfWeek }}
        longPressDelay={0}
      />
    </div>
  );
}
