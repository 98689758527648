import { gql } from "@apollo/client";

export const GET_ITEMS = gql`
  query ItemsGet {
    items: ItemsGet {
      _id
      name
      price
      picture
    }
  }
`;

export const GET_ITEM = gql`
  query ItemGet($_id: String) {
    item: ItemGet(_id: $_id) {
      _id
      name
      price
      picture
      available
      description
      brand
      reference
      youtubeLink
    }
    bookings: BookingsGet(itemId: $_id) {
      _id
      title
      start
      end
    }
    lockedDates: LockedDatesGet {
      _id
      title
      start
      end
    }
  }
`;
