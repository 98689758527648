import React from "react";
import logo from "../assets/logo_repair.jpeg";
import { useLocation } from "react-router-dom";
import { Link } from "./";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Nav() {
  let query = useQuery();
  if (query.get("embed")) return null; // hide nav if embed param is true
  return (
    <header>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" className="img-logo" />
            Repair café
          </Link>
        </div>
      </nav>
    </header>
  );
}
