import React from "react";
import NobuyLogo from "../assets/nobuy.png";
import RepairCafe from "../assets/logo_repair.jpeg";

export default function () {
  const link = "https://nobuy.app";
  return (
    <div className="end-phrase">
      <div>
        <img className="brand-logo" src={RepairCafe} /> &{" "}
        <a href={link} target="_blank">
          <img className="nobuy-logo" src={NobuyLogo} />
        </a>
      </div>
      <div>
        <a href={link} target="_blank">
          Créons ensemble la meilleure alternative à l'achat
        </a>
      </div>
    </div>
  );
}
